import React, { lazy, Suspense } from 'react';
import './style.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
const Home = lazy(() => import('./components/pages/Home'));
const About = lazy(() => import('./components/pages/About'));
const Projects = lazy(() => import('./components/pages/Projects'));
const Contact = lazy(() => import('./components/pages/Contact'));
const Header = lazy(() => import('./components/layout/Header'));
const Footer = lazy(() => import('./components/layout/Footer'));


export default function App() {

  return (
    <div className="cardWrapper">
      <div className="cardContainer">
        <div className="cardContent">
          <Router>
            <Header />
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/projects" element={<Projects/>} />
                <Route path="/contact" element={<Contact/>} />
              </Routes>
              </Suspense>
            <Footer />
          </Router>
        </div>
      </div>
  </div>
  );
}


